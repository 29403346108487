<template>
    <div>
        <div id="map" ref="map"></div>
        <button class="custom-map-control-button" @click="setUserLocation()">
            <button-icon></button-icon>
        </button>
        <info-window :stationID="activeChargingPoint" v-if="activeChargingPoint"></info-window>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    import MapRenderer from "../services/MapRenderer";
    import ButtonIcon from './icons/ButtonIcon';
    import InfoWindow from "./InfoWindow/InfoWindow";
    import ChargingStation from "../models/ChargingStation";
    import MapMarker from "../models/MapMarker";

    export default {
        name: "Map",
        props: {
            sentID: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                map: null,
                options: {
                    center: {
                        lat: 45.333,
                        lng: 14.442,
                    },
                    zoom: 6,
                    minZoom: 2,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    disableDefaultUI: true,
                },
                pos: null,
                activeChargingPoint: null,
                userMarker: null,
            }
        },
        async mounted() {
            this.map = new MapRenderer(this.$refs.map, this.options);
            await this.map.render();

            const chargingStations = await ChargingStation.all();
            chargingStations.forEach((marker) => {
                const url = ChargingStation.getIconPath(marker.location_type);
                const data = {
                    marker: marker,
                    url: url,
                }
                const mapMarker = new MapMarker(data);
                this.map.addMarker(mapMarker, (m) => {
                    this.setFocusedChargingPoint(m.attributes.id);
                });
            })

            this.map.onMapClick(()=>{
                this.setFocusedChargingPoint(null);
            });

            if(this.sentID){
                this.setFocusedChargingPoint(this.sentID);
                this.activeChargingPoint = this.sentID;
                this.map.focusMarker(this.sentID);
            }
        },
        computed:{
          ...mapGetters(['getFilters', 'focusedChargingPoint']),
        },
        methods: {
            ...mapActions(['setFocusedChargingPoint']),
            setUserLocation() {
                if (this.pos !== null) {
                    this.userMarker.setMap(null);
                }
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        this.map.setUserMarker(this.pos, (clickableEvent) => {
                            this.userMarker = clickableEvent;
                        });
                    },
                    () => {
                        alert('Error: The Geolocation service failed.');
                    }
                );
            },
        },
        watch: {
          getFilters: {
              deep: true,
              handler(filters){
                  const f = {
                      plug: filters.visiblePlugs || [],
                      partner: filters.visiblePartners || [],
                      territory: filters.visibleTerritories || [],
                  };
                  this.map.filterMarkers(f, 'plug');
              }
          },
            focusedChargingPoint(cpId){
              this.activeChargingPoint = cpId;
              if(cpId) {
                  this.map.focusMarker(cpId);
              }
            }
        },
        components: {
            ButtonIcon,
            InfoWindow
        }
    }
</script>

<style lang="scss" scoped>
    #map {
        width: 100%;
        height: calc(100vh - 40px);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .custom-map-control-button {
        position: absolute;
        bottom: 50px;
        right: 15px;
        padding: 10px;
        z-index: 10;
        border: none;
        border-radius: 50%;
        background-color: white;

        &:focus {
            border: none;
            outline: none;
        }
    }
</style>
