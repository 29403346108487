<template>
<div>
    <div class="primary lighten-3 mt-4">
        <v-container>
            <span class="primary--text text--darken-1">My registered vehicles:</span>
        </v-container>
    </div>
   <div class="pt-5 pb-2">
       <v-container>
           <v-btn outlined color="primary" rounded large @click="addNew" :disabled="newVehicle">
               <v-icon>mdi-plus-circle-outline</v-icon>
               Add new
           </v-btn>
       </v-container>
   </div>
    <VehicleCard :actions="true"></VehicleCard>
</div>
</template>

<script>
    import VehicleCard from "./VehicleCard";

    export default {
        name: "AllVehicles",
        props: {
            newVehicle: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            addNew() {
                this.$emit('addNew')
            }
        },
        components: {
            VehicleCard
        }
    }
</script>

<style scoped lang="scss">
.container{
    margin-left: 0;
    @media only screen and (min-width: 1200px){
        margin-left: 60px;
    }
}
button{
    text-transform: none;
    i{
        padding-right: 5px;
    }
}
</style>
