<template>
    <v-select :items="['UK', 'Croatia', 'Germany']" label="Country list" background-color="primary lighten-3" v-model="country" rounded solo flat dense>
        <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
export default {
    name: "CountryPicker",
    props: {
        pickedCountry: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            country: '',
        }
    }
}
</script>

<style scoped>

</style>
