<template>
    <v-card-text>
        <div class="d-flex align-center justify-center buttonsWrap mb-2">
            <v-btn tile
                   class="grey--text text--darken-2 py-5 d-flex align-center justify-center text-none font-weight-regular elevation-0 calculator"
                   :disabled="!check" @click="calculator = true">
                Calculator
                <calculator-icon class="ms-3"></calculator-icon>
            </v-btn>
            <v-btn tile class="grey--text py-5 d-flex align-center justify-center text-none font-weight-regular elevation-0 text--darken-2"
                   :disabled="!check" @click="reservation = true">
                Reserve
                <v-icon class="ms-3 grey--text text--darken-1">mdi-calendar-check</v-icon>
            </v-btn>
        </div>
        <charging-calculator v-if="calculator" @closeCalculator="closeCalculator" :stationData="stationData"></charging-calculator>
        <reservation-form v-if="reservation" @closeReservation="closeReservation" :stationData="stationData"></reservation-form>
        <info-list v-if="stationData" :stationData="stationData"></info-list>
        <v-divider></v-divider>
        <div class="d-flex justify-space-between px-4 pt-2 pb-4">
            <h4 class="font-bold black--text">Plugs:</h4>
        </div>
        <div class="d-flex align-center px-4 py-1" v-for="outlet in stationData.outlet_types" :key="outlet.id">
            <img src="../../assets/images/plugIcon.png" class="imgIcon"/>
            <p class="mb-0 grey--text text--darken-4 mx-2">{{ outlet.name }}</p>
            <div class="primary lighten-3 backgroundChange font-weight-black">
                <p class="mb-0 black--text">{{ outlet.count }} <span v-if="outlet.count > 1">plugs</span><span v-else>plug</span></p>
            </div>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-space-between px-4 pt-2">
            <h4 class="font-bold black--text">Photos:</h4>
        </div>
        <div class="d-flex align-center px-4 pt-4 pb-10">
            <div class="images" v-viewer>
                <img src="../../assets/images/temp.jpg" class="chargingImg me-3 me-sm-2"  />
            </div>
        </div>
    </v-card-text>
</template>

<script>
import CalculatorIcon from "../icons/CalculatorIcon";
import InfoList from "./InfoList";
import ChargingCalculator from "./ChargingCalculator";
import ReservationForm from "./ReservationForm";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import {mapGetters} from "vuex";
Vue.use(VueViewer, {
    defaultOptions: {
        movable: false,
        title: false,
        rotatable: false,
        scalable: false,
    }
})

export default {
    name: "InfoContent",
    props: {
        stationData:{
            type: Object,
            required: true
        }
    },
    data(){
        return{
            calculator: false,
            reservation: false,
            userAuth: false,
            images: [],
        }
    },
    mounted() {
        //axios za images
    },
    computed: {
        ...mapGetters('auth', ['check']),
    },
    methods:{
        closeCalculator(){
            this.calculator = false;
        },
        closeReservation(){
            this.reservation = false;
        }
    },
    components: {
        InfoList,
        CalculatorIcon,
        ChargingCalculator,
        ReservationForm
    }
}
</script>

<style lang="scss" scoped>
.buttonsWrap{
    border-bottom: 1px solid #009B42;
    .v-btn{
        width: 50%;
        font-family: "Roboto", sans-serif;
        background-color: #E7F4EF;
    }
    .calculator{
        border-right: 1px solid #009B42;
    }
}

.imgIcon {
    max-width: 40px;
    height: auto;
}

.backgroundChange {
    border-radius: 10px;
    padding: 2px 10px;
}

.chargingImg {
    width: 90px;
    height: 90px;
    object-fit: cover;
    cursor: pointer;
}

</style>
