<template>
    <div class="infoWindow" v-if="stationData.name" >
        <v-sheet class="text-left" :class="{enlarge: makeBigger}">
            <v-card class="elevation-0" :class="{enlarge: makeBigger}">
                <div class="primary darken-1 cardHeading">
                    <v-btn class="enlargeWindow" @click="makeBigger = !makeBigger"></v-btn>
                    <v-row no-gutters class="d-flex align-center">
                        <v-col>
                            <v-card-title class="white--text">{{ stationData.name }} -
                                {{ stationData.partner.name }}
                            </v-card-title>
                            <v-card-subtitle class="white--text pt-2">{{ stationData.location_type }}</v-card-subtitle>
                        </v-col>
                        <v-col cols="5" v-if="check">
                            <a class="d-flex align-center justify-center white--text" @click="addToFavourites" v-if="!favourite">
                                <span class="text-center">
                                Add to <br> favourites
                                </span>
                                <v-icon class="white--text ps-3">mdi-heart-outline</v-icon>
                            </a>
                            <a class="d-flex align-center justify-center white--text" @click="removeFromFavourites" v-if="favourite">
                                <span class="text-center">
                                Add to <br> favourites
                                </span>
                                <v-icon class="white--text ps-3">mdi-heart</v-icon>
                            </a>
                        </v-col>
                    </v-row>
                </div>
                <info-content v-if="stationData.name" :stationData="stationData"></info-content>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>
import InfoContent from "./InfoContent";
import ChargingStation from "../../models/ChargingStation";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "InfoWindow",
    props: {
        stationID: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            makeBigger: false,
            stationData: {},
            favourite: false,
        }
    },
    mounted() {
        this.findData();
    },
    computed: {
        ...mapGetters('auth', ['favourites', 'check']),
    },
    methods: {
        ...mapActions('auth', ['addFavourite', 'removeFavourite', 'getFavorites']),
        async findData() {
            if (this.stationID !== null) {
                this.stationData = await ChargingStation.find(this.stationID);
            }
            if(this.check){
                await this.getFavorites();
                const fav = this.favourites;
                if(fav.find(f => f === this.stationID)){
                    this.favourite = true;
                }else{
                    this.favourite = false;
                }
            }
        },
        addToFavourites(){
            this.addFavourite(this.stationID);
            this.favourite = true;

        },
        removeFromFavourites(){
            this.removeFavourite(this.stationID);
            this.favourite = false;
        }
    },
    watch: {
        stationID() {
            this.findData();
        },
    },
    components: {
        InfoContent
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/InfoWindow";
</style>
