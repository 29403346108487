<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-card-title class="primary lighten-2 white--text py-3 d-flex justify-space-between">
            <span>Settings</span>
            <a @click="closeSettings" >
                <v-icon class="white--text">mdi-close</v-icon>
            </a>
        </v-card-title>
    <section class="settings white py-6 px-6">
        <p class="primary--text">Language:</p>
        <language-picker :pickedLanguage="pickedLanguage"></language-picker>
        <p class="primary--text">Country:</p>
        <country-picker :pickedCountry="pickedCountry"></country-picker>
    </section>
    </v-dialog>
</template>

<script>
import LanguagePicker from "./LanguagePicker";
import CountryPicker from "./CountryPicker";
export default {
    name: "SetSettings",
    data(){
        return{
            dialog: true,
            pickedLanguage: '',
            pickedCountry: '',
        }
    },
    mounted() {
        //TODO - ovdje učitati izbor jezika
        this.pickedLanguage = "English";
        this.pickedCountry = "Croatia";
    },
    methods: {
      closeSettings(){
          this.dialog = false;
          this.$emit("closeSettings");
      },
    },
    watch: {
        dialog(){
            if(this.dialog === false){
                this.$emit("closeSettings");
            }
        },
        pickedLanguage(){

        },
        pickedCountry(){

        }
    },
    components: {
        LanguagePicker,
        CountryPicker
    }
}
</script>

<style scoped lang="scss">
.settings {
    height: 250px;
    .v-input__slot{
        padding-top: 5px;
    }
    button{
        width: 100px;
    }
    p{
        margin-bottom: 8px;
    }
}
</style>
