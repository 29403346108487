<template>
    <v-dialog v-model="dialog" max-width="400" content-class="helpWindow">
        <v-card>
            <v-card-title class="primary lighten-2 white--text py-3 d-flex justify-space-between">
                <span>Help</span>
                <a @click="closeHelp" >
                    <v-icon class="white--text">mdi-close</v-icon>
                </a>
            </v-card-title>
            <v-card-text class="pt-3 pb-5">
                <p class="primary--text text--darken-1 pt-2">Search for help:</p>
                <div class="searchWrap">
                    <input type="text" name="searchHelp" id="searchHelp" placeholder="Search" v-model="query"/>
                    <v-btn class="rounded-circle white grey--text text--darken-2 elevation-0">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </div>
            </v-card-text>
            <help-legend></help-legend>
            <help-how-to-use></help-how-to-use>
            <help-faq></help-faq>
        </v-card>
    </v-dialog>
</template>

<script>
import HelpLegend from "./HelpLegend";
import HelpHowToUse from "./HelpHowToUse";
import HelpFaq from "./HelpFaq";
export default {
    name: "HelpWindow",
    data(){
        return{
            dialog: true,
            query: '',
        }
    },
    methods:{
        closeHelp(){
            this.dialog = false;
            this.$emit("closeHelp");
        },
    },
    watch:{
        dialog(){
            if(this.dialog === false){
                this.$emit("closeHelp");
            }
        }
    },
    components: {
        HelpLegend,
        HelpHowToUse,
        HelpFaq
    }
}
</script>

<style scoped lang="scss">
.v-sheet.v-card{
    .searchWrap{
        position: relative;
        input {
            border-radius: 20px;
            border: 1px solid #009B42;
            padding: 10px 15px;
            width: 100%;

            &:active, &:hover, &:focus {
                background-color: transparent;
            }
        }
        .rounded-circle{
            position: absolute;
            background-color: transparent;
            right: 10px;
            top: 4px;
            max-width: 30px;
            min-width: 30px;
            width: 30px;
        }
    }
    p{
        margin-bottom: 8px;
    }
}

</style>
