<template>
    <v-list-group>
        <v-list-item slot="activator" class="d-flex align-center">
            <v-list-item-icon>
                <v-icon class="smallNegativeM">mdi-filter-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-1 smallNegativeM font-weight-regular"><span>Filters</span></v-list-item-title>
        </v-list-item>
        <v-list-item sub-group no-action value="true" class="d-block plugs">
            <v-list-item-title class="py-4 pl-5 primary--text backgroundColor font-weight-regular">Plug type</v-list-item-title>
            <plug-type></plug-type>
            <!--<v-list-item-title class="py-4 pl-5 primary--text backgroundColor">Price</v-list-item-title>
            <price-filter></price-filter>-->
            <v-list-item-title class="py-4 pl-5 primary--text backgroundColor font-weight-regular">Region</v-list-item-title>
            <region-filter></region-filter>
            <v-list-item-title class="py-4 pl-5 primary--text backgroundColor font-weight-regular">Partner</v-list-item-title>
            <partner-filter></partner-filter>
        </v-list-item>
    </v-list-group>
</template>

<script>
import PlugType from "./PlugType";
//import PriceFilter from "./PriceFilter";
import RegionFilter from "./RegionFilter";
import PartnerFilter from "./PartnerFilter";

export default {
    name: "Filters",
    components: {
        PlugType,
        //PriceFilter,
        RegionFilter,
        PartnerFilter
    }
}
</script>

<style scoped lang="scss">
.plugs{
    margin: auto -16px;
}
.v-list--dense .v-list-item .v-list-item__title {
    font-size: 17px;
    overflow: visible;
}
.v-list-group__header.v-list-item.v-list-item--link{
    padding: 0;
}
.smallNegativeM{
    margin-left: -8px;
}
.backgroundColor{
    background-color: #E7F4EF;
}

</style>
