<template>
    <div class="primary lighten-3">
        <v-container>
            <v-row class="d-flex align-center pt-7 pb-5">
                <div>
                    <v-icon size="60" color="primary" class="pe-4">mdi-account-outline</v-icon>
                </div>
                <div>
                    <span class="grey--text text--darken-4 font-weight-black subheading">{{ user.name }}</span> <br>
                    <span class="primary--text">{{ title }}</span>
                </div>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        props: {
            title: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapGetters('auth', ['user']),
        },
    }
</script>

<style lang="scss" scoped>
   .container{
        margin-left: 0;
        @media only screen and (min-width: 1200px){
            margin-left: 60px;
        }
    }
</style>
