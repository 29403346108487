<template>
    <v-list nav dense active-class="deep-purple--text text--accent-4">
        <filters-navigation></filters-navigation>
        <v-list-item>
            <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-1 clickableItem font-weight-regular"
                               @click="openSettings">Settings
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon>
                <v-icon :class="[ 'grey--text', check ? 'text--darken-1' : '']">mdi-car-electric-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="[ 'grey--text clickableItem font-weight-regular', check ? 'text--darken-1' : '']"
                               @click="openVehicles">Vehicles
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon>
                <v-icon :class="[ 'grey--text', check ? 'text--darken-1' : '']">mdi-heart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="[ 'grey--text clickableItem font-weight-regular', check ? 'text--darken-1' : '']"
                               @click="openFavourites">Favourites
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon>
                <v-icon :class="[ 'grey--text', check ? 'text--darken-1' : '']">mdi-calendar-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="[ 'grey--text clickableItem font-weight-regular', check ? 'text--darken-1' : '']"
                               @click="openReservations">Reservations
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon>
                <v-icon :class="[ 'grey--text', check ? 'text--darken-1' : '']">mdi-history</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="[ 'grey--text clickableItem font-weight-regular', check ? 'text--darken-1' : '']"
                               @click="openHistory"><span>Charging history</span></v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon>
                <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-1 clickableItem font-weight-regular" @click="openHelp">
                Help
            </v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
import FiltersNavigation from "./Filters/FiltersNavigation";
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('auth', ['check']),
    },
    methods: {
        openSettings() {
            this.$emit("openSettings");
        },
        openHelp() {
            this.$emit("openHelp");
        },
        openHistory() {
            if (this.check) {
                this.$router.push({
                    name: 'ChargingHistory',
                })
            }
        },
        openFavourites() {
            if (this.check) {
                this.$router.push({
                    name: 'FavouritesScreen',
                })
            }
        },
        openVehicles() {
            if (this.check) {
                this.$router.push({
                    name: 'UserVehicle',
                })
            }
        },
        openReservations() {
            if (this.check) {
                this.$router.push({
                    name: 'ReservationScreen',
                })
            }
        },
    },
    components: {
        FiltersNavigation
    }
}
</script>

<style lang="scss" scoped>
.v-list--dense .v-list-item .v-list-item__title {
    font-size: 17px;
    overflow: visible;
}

.v-list-group__header.v-list-item.v-list-item--link {
    padding: 0;
}

.clickableItem {
    cursor: pointer;
}
</style>
