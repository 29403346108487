<template>
    <div class="home">
        <Navigation @openSettings="openSettings" @openHelp="openHelp"></Navigation>
        <main-map :sentID="sentID"></main-map>
        <set-settings v-if="settings" @closeSettings="closeSettings" @saveSettings="saveSettings"></set-settings>
        <help-window v-if="help" @closeHelp="closeHelp"></help-window>
    </div>
</template>

<script>
import MainMap from "../components/MainMap";
import Navigation from "../components/Navigation/NavigationBar";
import SetSettings from "../components/Settings/SetSettings";
import HelpWindow from "../components/Help/HelpWindow";

export default {
    name: 'Home',
    data() {
        return {
            settings: false,
            help: false,
            sentID: null,
        }
    },
    created() {
        if (this.$route.params.id) {
            this.sentID = this.$route.params.id;
        }
    },
    methods: {
        openSettings() {
            this.settings = true;
        },
        closeSettings() {
            this.settings = false;
        },
        saveSettings() {
            this.closeSettings();
        },
        openHelp() {
            this.help = true;
        },
        closeHelp() {
            this.help = false;
        },
    },
    components: {
        Navigation,
        MainMap,
        SetSettings,
        HelpWindow
    }
}
</script>
