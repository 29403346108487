<template>
    <v-container>
        <form>
            <div class="form-group pb-3">
                <label for="username" class="primary--text pb-1">Username:</label>
                <input type="text" class="form-control" id="username" name="Username" placeholder="Username" v-model="username" :disabled="!changeUsername">
                <a href="#" class="editButton" @click="update()">
                    <span class="primary--text text--darken-1" v-if="!changeUsername">Edit</span>
                    <span class="primary--text text--darken-1" v-else>Save</span>
                    <v-icon class="primary--text text--darken-1">mdi-square-edit-outline</v-icon>
                </a>
            </div>
            <div class="form-group pb-3">
                <label for="password" class="primary--text pb-1">Password:</label>
                <input type="password" id="password" class="form-control" placeholder="Password" v-model="password"
                       name="current-password" autocomplete="on" :disabled="!changePass">
                <!--<a href="#" class="editButton" @click="changePass=!changePass">
                    <span class="primary--text text--darken-1" v-if="!changePass">Edit</span>
                    <span class="primary--text text--darken-1" v-else>Save</span>
                    <v-icon class="primary--text text--darken-1">mdi-square-edit-outline</v-icon>
                </a>-->
            </div>
            <div class="form-group pb-3">
                <label for="email" class="primary--text pb-1">E-mail:</label>
                <input type="email" class="form-control" id="email" name="mail" placeholder="Email" v-model="email" disabled>
            </div>
        </form>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import axios from "axios";

    export default {
    name: "ProfileDetails",
    data(){
        return{
            email: "",
            password: "",
            username: "",

            changeUsername: false,
            changePass: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['user']),
    },
    mounted() {
        this.email = this.user.email;
        this.username = this.user.name;
        this.password = 'pass123';
    },
        methods: {
            ...mapActions('auth', ['getUser']),
            update(){
                this.changeUsername = !this.changeUsername;
                if(this.changeUsername === false){
                    axios.put('/api/user', {
                        name: this.username,
                    }).then(() => {
                        this.getUser();
                    })
                }
            },
        },
}
</script>

<style scoped lang="scss">
.container{
    margin-left: 0;
    @media only screen and (min-width: 1200px){
        margin-left: 60px;
    }
}
form{
    margin: 20px 0 20px 0;
    @media only screen and (min-width: 992px){
        max-width: 850px;
    }
    .form-group{
        position: relative;
        label{
            display: block;
        }
        input:disabled{
            background-color: #EEEEEE;
        }
        input{
            border-radius: 20px;
            border: 1px solid lightgray;
            padding: 8px 16px;
            width: 100%;
        }
        .editButton {
            position: absolute;
            right: 14px;
            bottom: 20px;
            text-decoration: none;
            display: flex;
            align-items: center;
            span{
                display: block;
            }
            i{
                font-size: 20px;
                padding-left: 4px;
            }
        }
    }
}

</style>
