import { render, staticRenderFns } from "./ButtonIcon.vue?vue&type=template&id=024a09bf&scoped=true&"
var script = {}
import style0 from "./ButtonIcon.vue?vue&type=style&index=0&id=024a09bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024a09bf",
  null
  
)

export default component.exports