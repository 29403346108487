<template>
    <div class="history">
        <Header title="Charging history"/>
        <back-button text="Back to menu" url="Home"></back-button>
        <history-card></history-card>
    </div>
</template>


<script>
import BackButton from "../components/BackButton";
import HistoryCard from "../components/HistoryCard";
import Header from "../components/Profile/Header";

export default {
    name: "ChargingHistory",
    components: {
        BackButton,
        HistoryCard,
        Header
    }
}
</script>

<style scoped lang="scss">
.container{
    margin-left: 0;
    @media only screen and (min-width: 1200px){
        margin-left: 60px;
    }
}
</style>
