<template>
    <v-select :items="['English', 'Croatian', 'Deutsch']" background-color="primary lighten-3" label="Language list" v-model="language" rounded solo flat dense>
        <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
export default {
    name: "LanguagePicker",
    props: {
        pickedLanguage: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            language: '',
        }
    },
    watch: {

    }
}
</script>

<style scoped>

</style>
