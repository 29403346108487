<template>
  <v-app>
      <Navbar></Navbar>
      <v-main class="white">
          <router-view/>
      </v-main>
  </v-app>
</template>

<script>
    import Navbar from "./components/Navigation/Navbar";
    import {mapActions, mapGetters} from "vuex";

    export default {
        mounted() {
            if (this.check === false) {
                this.login();
            }
        },
        computed: {
            ...mapGetters('auth', ["check"]),
        },
        methods: {
            ...mapActions('auth', ['login']),
        },
        components: {
            Navbar
        },
    }
</script>

<style lang="scss">
@import "assets/scss/shared";
</style>
