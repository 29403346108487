<template>
    <v-row>
    <v-col cols="12" class="mainNavButtons">
        <div class="d-flex">
            <v-app-bar-nav-icon class="white grey--text text--darken-2"
                                @click="toggleNav"></v-app-bar-nav-icon>
            <div class="biggerScreens">
                <!--<input type="text" name="searchBar" id="searchBar1" placeholder="Search" v-model="query"/>
                <v-btn class="rounded-circle white grey--text text--darken-2 elevation-0">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>-->
                <input type="text" name="searchBar" id="searchBar1" placeholder="Search" v-model="query" autocomplete="off"/>
                <ul v-if="query.length>=2 && this.stations.length >= 1">
                    <li v-for="station in showStations" :key="station.id" @click="pickStation(station.id)">
                        {{ station.name }} </li>
                </ul>
                <v-btn class="rounded-circle white grey--text text--darken-2 elevation-0">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </div>
        </div>

        <div class="smallScreens">
           <input type="text" name="searchBar" id="searchBar" placeholder="Search" v-model="query"
                   v-if="search"  autocomplete="off"/>
             <v-btn class="rounded-circle grey--text text--darken-2"
                    v-bind:class="search === true ? 'transparent changeColor' : 'white'" @click="search=!search">
                 <v-icon>mdi-magnify</v-icon>
             </v-btn>
            <ul v-if="showStations.length >= 1 && search && query.length>=2">
                <li v-for="station in showStations" :key="station.id" @click="pickStation(station.id)">
                    {{ station.name }} </li>
            </ul>
         </div>
     </v-col>
    </v-row>
 </template>

 <script>
 import ChargingStation from "../../models/ChargingStation";
 import {mapActions} from 'vuex'

 export default {
     name: "NavigationOpenSearch",
     data(){
         return{
             query: "",
             search: false,
             stations: [],
             showStations: [],
         }
     },
     mounted() {
         this.loadStations();
     },
     computed: {
         filteredStations() {
             if (this.query.length < 2) {
                 return [];
             }

             const q = this.query.toLowerCase();


             return this.stations.filter((station) => {
                 return station.name.toLowerCase().includes(q);
             });
         },
     },
     methods: {
         ...mapActions(['setFocusedChargingPoint']),
         toggleNav() {
             this.$emit("toggleDrawer");
         },
         async loadStations(){
             this.stations = await ChargingStation.all();
         },
         async pickStation(id){
             this.search = false;
             this.query = '';
             this.setFocusedChargingPoint(id);
         }
     },
     watch: {
         query(){
             this.showStations = this.filteredStations;
         },
     }
 }
 </script>

 <style scoped lang="scss">
 @import "../../assets/scss/OpenSearch";
 </style>
