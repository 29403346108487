import axios from 'axios';

export default class Vehicle {
    constructor(props) {
        this.id = props.id;
        this.manufacturer = props.manufacturer;
        this.model = props.model;
        this.year = props.year;
        this.variants = props.variants || [];
    }

    async loadVariants() {
        if (this.variants.length > 0) {
            return;
        }

        const {data} = await axios.get(`/api/vehicle/${this.id}`);
        this.variants = data.variants;
    }

    static all() {
        return new Promise(((resolve, reject) => {
            axios.get('/api/vehicle').then((response) => {
                resolve(response.data.map(item => new Vehicle(item)));
            }, reject);
        }));
    }

    static find(id) {
        return new Promise((resolve) => {
            axios.get(`/api/vehicle/${id}`).then((response) => {
                resolve(new Vehicle(response.data));
            });
        });
    }

}
