<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
        <path fill-rule="evenodd"
              d="M61.438 0c33.93 0 61.441 27.512 61.441 61.441s-27.512 61.438-61.441 61.438C27.512 122.88 0 95.37 0 61.441S27.512 0 61.438 0z"
              clip-rule="evenodd"/>
    </svg>
</template>

<script>
export default {
    name: "circle"
}
</script>

<style scoped>

</style>
