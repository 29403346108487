<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" lg="4">
                <v-card flat>
                    <div class="primary lighten-3 py-2 px-5">
                        <v-icon class="primary--text">mdi-map-marker-outline</v-icon>
                        Centar Tower
                    </div>
                    <v-card-text class="text--primary">
                        <div class="py-2">
                            <v-row no-gutters class="d-flex align-center">
                                <v-col>
                                    <div class="d-flex align-start py-2">
                                        <v-icon class="pe-3">mdi-map-marker-radius-outline</v-icon>
                                        Ul. Janka Polića Kamova 81a,
                                        51000, Rijeka
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-phone-in-talk</v-icon>
                                        051 403 815
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-handshake-outline</v-icon>
                                        HEP
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-information-outline</v-icon>
                                        01.12.2021. - 13:30-14:30h
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="d-flex justify-space-between buttonWrap">
                            <a class="primary--text text--darken-1" @click="openOnMap">Open
                                <v-icon class="primary--text text--darken-1">mdi-map-marker-outline</v-icon>
                            </a>
                            <a class="red--text" @click="removeThis">Remove reservation
                                <v-icon class="red--text">mdi-calendar-check</v-icon>
                            </a>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "ReservationCard",
    methods: {
        ...mapActions(['setFocusedChargingPoint']),
        openOnMap(){
            //zamijeniti ID s ispravnim ID-om

            this.$router.push({
                name: 'Home',
                params: {id: 1}
            })
            this.setFocusedChargingPoint(1);
        },
        removeThis(){
            //delete from favorites
        },
    }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/Card";
</style>
