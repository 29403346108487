export default class UserVehicle {
    constructor(props) {
        Object.assign(this, props);
    }

    getManufacturer() {
        return this._getVehicle().manufacturer;
    }

    getModel() {
        return this._getVehicle().model;
    }

    getYear() {
        return this._getVehicle().year;
    }

    getVariantName() {
        return this.getVariant().name;
    }

    getVariant() {
        return this.vehicleVariant;
    }

    getBatteryCapacity() {
        return this.vehicleVariant.battery_capacity;
    }

    _getVehicle() {
        return this.vehicleVariant.vehicle;
    }
}
