import Vue from 'vue';
import axios from "axios";
import vuetify from './plugins/vuetify';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
