<template>
    <div>
        <div class="primary lighten-3">
            <v-container>
                <span class="primary--text text--darken-1">My registered vehicles:</span>
            </v-container>
        </div>
        <VehicleCard :actions="false"></VehicleCard>
        <v-container>
            <a class="primary--text text--darken-1" @click="goToVehicles">Go to registered vehicles >></a>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import VehicleCard from "../Vehicles/VehicleCard";

export default {
    name: "GoToVehicles",
    computed: {
        ...mapGetters('auth', ['vehicles']),
    },
    methods: {
        goToVehicles(){
            this.$router.push({
                name: 'UserVehicle',
            })
        },
    },
    components: {
        VehicleCard
    }
}
</script>

<style scoped lang="scss">
.container {
    margin-left: 0;
    @media only screen and (min-width: 1200px) {
        margin-left: 60px;
    }
}

.v-card{
    .v-card__text{
        border: 1px solid lightgray;
        width: 100%;
    }
}
</style>
