<template>
    <v-list-item-content class="px-3">
        <v-select
            @input="updateFilter"
            :items="partners"
            item-text="name"
            item-value="id"
            label="Select Partners"
            multiple chips>
        </v-select>
    </v-list-item-content>
</template>

<script>
    import axios from "axios";
    import { BASE_URL } from '../../../config/variables';
    export default {
        name: "PartnerFilter",
        data() {
            return {
                partners: [],
            }
        },
        async mounted() {
            const {data} = await axios.get(BASE_URL +'/partner');
            this.partners = data;
        },
        methods: {
            updateFilter(partners){
                this.$store.commit('SET_UI_FILTERS_PARTNERS', partners);
            }
        },
        beforeDestroy() {
            this.$store.commit('SET_UI_FILTERS_PARTNERS', []);
        }
    }
</script>

<style scoped lang="scss">
    .plugs {
        a {
            background-color: transparent;
            box-shadow: 4px 4px 4px #E7F4EF;
            display: block;
            margin: 5px 5px;
            padding: 10px;

            div {
                text-align: center;

                .v-icon {
                    font-size: 70px;
                }
            }
        }

        a.active {
            border: 1px solid #009B42;
        }
    }
</style>
