<template>
    <v-dialog v-model="dialog" max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                Are you sure you want to delete the vehicle?
            </v-card-title>
            <v-card-text>
                This action can't be undone.
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="grey" class="white--text elevation-0" @click="cancel">
                    Cancel
                </v-btn>
                <v-btn color="red" class="white--text elevation-0"  @click="deleteVehicle">
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteVehicle",
    data(){
      return{
          dialog: true,
      }
    },
    methods:{
        cancel(){
            this.dialog = false;
            this.$emit('cancel');
        },
        deleteVehicle(){
            this.dialog = false;
            this.$emit('deleteVehicle');
        }
    },
    watch: {
        dialog() {
            if (this.dialog === false) {
                this.$emit('cancel');
            }
        },
    }
}
</script>

<style scoped>

</style>
