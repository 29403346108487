<template>
    <section class="login">
        <div class="py-10">
            <user-icon></user-icon>
        </div>
        <form @submit.prevent="sendPassword">
            <div class="text-center">
                <p class="primary--text mb-2">Forgot your password?</p>
                <p>Don't worry! Just fill in your email, and we'll send you the link for the reset.</p>
            </div>
            <div class="form-group pb-3">
                <input type="email" class="form-control" name="email" placeholder="Email" v-model="email" required>
            </div>
            <div class="form-group pt-4 text-center">
                <v-btn class="primary" type="submit" rounded large>Reset password</v-btn>
            </div>
        </form>
        <div class="fakeDiv"></div>
        <register-button></register-button>
    </section>
</template>

<script>
import UserIcon from "../components/icons/UserIcon";
import RegisterButton from "../components/RegisterButton"
export default {
    data(){
        return{
            email: '',
        }
    },
    methods:{
        sendPassword(){
            //axios za lost pass
        },
    },
    components: {
        UserIcon,
        RegisterButton
    }
}
</script>

<style lang="scss" scoped>
.login {
    width: 100%;
    //height: calc(100vh - 40px);
    min-height: 100%;
    position: relative;

    .fakeDiv {
        height: 190px;
    }

    @media only screen and (min-width: 576px) {
        width: 80%;
        margin: auto;
    }
    @media only screen and (min-width: 776px) {
        width: 600px;
    }
}
form{
    width: 85%;
    display: block;
    margin: auto;

    .form-group{
        position: relative;
        input {
            width: 100%;
            height: 42px;
            padding: 0 40px 0 20px;
            border-radius: 25px;
            background-color: #E7F4EF;
            &:active, &:hover, &:focus{
                background-color: #E7F4EF;
            }
        }
        input::placeholder{
            color: #009B42;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px #E7F4EF inset ;
        }
        button{
            width: 100%;
        }
    }
}
</style>
