<template>
    <div>
        <div class="primary lighten-3 py-2 px-5">
            <span class="primary--text text--darken-1">FAQ:</span>
        </div>
        <div class="py-5 px-7">
            <div>
                <p>
                    1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                </p>
                <p>
                    2. incididunt ut labore et dolore magna aliqua.
                </p>
                <p>
                    3.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                </p>
            </div>
            <div v-if="readMore">
                <p>
                    4. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                </p>
                <p>
                    5. incididunt ut labore et dolore magna aliqua.
                </p>
                <p>
                    6.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                </p>
            </div>
            <div class="pb-3">
                <a class="primary--text" @click="readMore = true" v-if="!readMore">Read more
                    <v-icon class="primary--text">mdi-chevron-down</v-icon></a>
                <a class="primary--text" @click="readMore = false" v-if="readMore">Read less
                    <v-icon class="primary--text">mdi-chevron-up</v-icon></a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "HelpFAQ",
    data(){
        return{
            readMore: false,
        }
    }
}
</script>

<style scoped>

</style>
