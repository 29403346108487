<template>
   <div>
       <Header title="Registered vehicles"/>
       <back-button text="Back to menu" url="Home"></back-button>
       <add-new-vehicle v-if="newVehicle" @addVehicle="addVehicle"></add-new-vehicle>
       <all-vehicles @addNew="addNew" :newVehicle="newVehicle"></all-vehicles>
   </div>
</template>

<script>
    import BackButton from "../components/BackButton";
    import AddNewVehicle from "../components/Vehicles/AddNewVehicle";
    import AllVehicles from "../components/Vehicles/AllVehicles";
    import Header from "../components/Profile/Header";

    export default {
    data(){
        return{
            newVehicle: false,
        }
    },
    methods: {
        addNew(){
            this.newVehicle = true;
        },
        addVehicle(){
            this.newVehicle = false;
        },
    },
    components: {
        BackButton,
        AddNewVehicle,
        Header,
        AllVehicles,
    }
}
</script>

<style scoped lang="scss">

</style>
