<template>
    <v-system-bar flat dense app height="40" class="primary">
        <router-link :to="{name: 'Home'}" text class="white--text">
            <img src="../../assets/images/LOGO-mobile.png" alt="EnerNETMob logo">
        </router-link>
    </v-system-bar>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.v-system-bar{
    z-index: 250;
    a {
        text-decoration: none;
        padding: 0 0 0 10px;

        img{
            padding-top: 2.5px;
            max-height: 35px;
            width: auto;
        }
    }
}
</style>
