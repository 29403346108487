<template>
    <div>
        <Header title="Favourites"/>
        <back-button text="Back to menu" url="Home"></back-button>
        <favourite-card></favourite-card>
    </div>
</template>

<script>
import BackButton from "../components/BackButton";
import FavouriteCard from "../components/FavouriteCard";
import Header from "../components/Profile/Header";

export default {
    name: "FavouritesScreen",
    components: {
        BackButton,
        FavouriteCard,
        Header
    }
}
</script>

<style scoped lang="scss">
.container {
    margin-left: 0;
    @media only screen and (min-width: 1200px) {
        margin-left: 60px;
    }
}
</style>
