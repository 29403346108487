<template>
    <div class="history">
        <Header title="Reservations"/>
        <back-button text="Back to menu" url="Home"></back-button>
        <reservation-card></reservation-card>
    </div>
</template>


<script>
import BackButton from "../components/BackButton";
import ReservationCard from "../components/ReservationCard";
import Header from "../components/Profile/Header";

export default {
    name: "ReservationScreen",
    components: {
        BackButton,
        ReservationCard,
        Header
    }
}
</script>

<style scoped lang="scss">
.container{
    margin-left: 0;
    @media only screen and (min-width: 1200px){
        margin-left: 60px;
    }
}
</style>
