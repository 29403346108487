<template>
    <div>
        <div class="d-flex justify-space-between align-center headerWrap" v-if="!check">
            <!-- NELOGIRANI USER -->
            <router-link :to="{name: 'ProfileScreen'}">
                <user-icon></user-icon>
            </router-link>
            <p class="grey--text text--darken-1 pl-4">Create an account to use all the features of this
                app!</p>
        </div>
        <div class="d-flex align-center headerWrap" v-if="check">
            <!-- LOGIRANI USER -->
            <v-icon size="60" color="primary">mdi-account-outline</v-icon>
            <p class="ps-3"><span class="grey--text text--darken-4">{{ user.name }}</span> <br>
                <router-link :to="{name: 'ProfileScreen'}" class="primary--text">View profile</router-link>
            </p>
        </div>
    </div>
</template>
<script>
import UserIcon from "../icons/UserIcon";
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('auth', ['user', 'check']),
    },
    data(){
        return{
        }
    },
    components: {
        UserIcon
    }
}
</script>
<style lang="scss" scoped>
.headerWrap {
    padding: 20px 30px 20px 10px;
    border-bottom: 1px solid #707070;

    p {
        margin-bottom: 0;
    }

    a{
        text-decoration: none;
    }
}
</style>
