<template>
    <v-list>
        <v-list-item>
            <v-list-item-icon>
                <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                <span>
                    {{ stationData.address }}
                </span>
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon>
                <v-icon>mdi-phone-in-talk</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                Contact
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon>
                <v-icon>mdi-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                Open 24/7
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                http://www.charge-point.hr
            </v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: "InfoList",
    props: {
        stationData: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.v-list-item__title{
    white-space: inherit;
}
.v-list-item {
    min-height: 35px;
}

.v-list-item__icon {
    margin: 8px 0;
}

</style>
