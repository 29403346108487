<template>
    <nav>
        <v-app-bar flat class="transparent changeZ">
            <navigation-open-search @toggleDrawer="toggleDrawer"></navigation-open-search>
        </v-app-bar>
        <v-navigation-drawer absolute temporary v-model="drawer" width="80%">
            <div class="relative">
                <navigation-header></navigation-header>
                <navigation-list @openSettings="openSettings" @openHelp="openHelp"></navigation-list>
                <div class="fakeDiv"></div>
                <navigation-buttons></navigation-buttons>
            </div>
        </v-navigation-drawer>
    </nav>
</template>

<script>
import NavigationList from './NavigationList';
import NavigationHeader from './NavigationHeader';
import NavigationButtons from './NavigationButtons'
import NavigationOpenSearch from "./NavigationOpenSearch";

export default {
    data() {
        return {
            drawer: false,
        }
    },
    methods: {
        toggleDrawer(){
            this.drawer = !this.drawer;
        },
        openSettings(){
            this.drawer = false;
            this.$emit("openSettings");
        },
        openHelp(){
            this.drawer = false;
            this.$emit("openHelp");
        },
    },
    components: {
        NavigationList,
        NavigationHeader,
        NavigationButtons,
        NavigationOpenSearch
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/navigationBar";
</style>
