import axios from 'axios';
import {GET_PLUGS} from '../config/variables';

export default class Plug {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
    }

    static all() {
        return new Promise(((resolve, reject) => {
            axios.get(GET_PLUGS).then((response) => {
                resolve(response.data.map(item => new Plug(item)));
            }, reject);
        }));
    }

    static find(id) {
        return new Promise((resolve) => {
            axios.get(GET_PLUGS+`/${id}`).then((response) => {
                resolve(new Plug(response.data));
            });
        });
    }

}
