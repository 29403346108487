import { render, staticRenderFns } from "./RegisterButton.vue?vue&type=template&id=315d59b3&scoped=true&"
var script = {}
import style0 from "./RegisterButton.vue?vue&type=style&index=0&id=315d59b3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315d59b3",
  null
  
)

export default component.exports