<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" lg="4" v-for="fav in favouritesData" :key="fav.id">
                <v-card flat>
                    <div class="primary lighten-3 py-2 px-5 header d-flex align-center">
                        <v-icon class="primary--text me-3">mdi-heart</v-icon>
                        {{ fav.name }}
                    </div>
                    <v-card-text class="text--primary d-flex align-center">
                        <div class="py-2">
                            <v-row no-gutters class="d-flex align-center">
                                <v-col class="me-2">
                                    <div class="d-flex align-start py-2">
                                        <v-icon class="pe-3">mdi-map-marker-radius-outline</v-icon>
                                        {{ fav.address }}
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-phone-in-talk</v-icon>
                                        +38512345678
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-handshake-outline</v-icon>
                                        {{ fav.partner.name }}
                                    </div>
                                </v-col>
                                <v-col>
                                    <!-- TODO - IMAGES HERE -->
                                    <img src="../assets/images/tempFavourites.jpg" alt="#">
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="d-flex justify-space-between buttonWrap">
                            <a class="primary--text text--darken-1" @click="openOnMap(fav.id)">Open
                                <v-icon class="primary--text text--darken-1">mdi-map-marker-outline</v-icon>
                            </a>
                            <a class="red--text" @click="removeThis(fav.id)">Remove
                                <v-icon class="red--text">mdi-heart-outline</v-icon>
                            </a>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ChargingStation from "../models/ChargingStation";

export default {
    name: "FavouriteCard",
    data(){
        return{
            favouritesData: [],
        }
    },
    mounted() {
        this.loadFavourites();
    },
    computed: {
        ...mapGetters('auth', ['favourites']),
    },
    methods: {
        ...mapActions(['setFocusedChargingPoint']),
        ...mapActions('auth', ['removeFavourite']),
        async loadFavourites(){
            const arr = this.favourites.map((id) =>{
                return ChargingStation.find(id);
            })
            Promise.all(arr).then((values) => {
                this.favouritesData = values;
            })
        },
        openOnMap(id){
            this.$router.push({
                name: 'Home',
                params: {id: id}
            })
            this.setFocusedChargingPoint(id);
        },
        removeThis(id){
            this.removeFavourite(id);
        },
    },
    watch: {
        favourites(){
            this.loadFavourites();
        }
    }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/Card";
</style>
