import axios from 'axios';

export default class ChargingStation {
    constructor(props) {
        this.id = props.id;
        if (props.location) {
            this.lat = props.location.lat;
            this.lng = props.location.long;
        } else {
            this.lat = props.latitude;
            this.lng = props.longitude;
        }
        this.location_type = props.location_type;
        this.name = props.name;
        this.electricity_supplier = props.electricity_supplier;
        this.address = props.address;
        this.outlet_types = props.outlet_types;
        this.battery_charger = props.battery_charger;
        this.charging_mode = props.charging_mode;
        this.partner = props.partner;
        this.territory_id = props.territory_id;
        // TODO - dodati input i output power što će trebati za kalkulator (pitati odmah i za formulu)
    }

    static getIconPath(locationType) {
        let url = {active: "", passive: ""};
        if (locationType === 'street') {
            url.passive = '/img/red-pin.png';
            url.active = '/img/red-pin-outline.png';
        } else {
            url.passive = "/img/pin-normal.png";
            url.active = "/img/pin-line.png";
        }
        return url;
    }

    static all() {
        return new Promise(((resolve, reject) => {
            axios.get('/api/charging-point').then((response) => {
                resolve(response.data.map(item => new ChargingStation(item)));
            }, reject);
        }));
    }

    static find(id) {
        return new Promise((resolve) => {
            axios.get( `/api/charging-point/${id}`).then((response) => {
                resolve(new ChargingStation(response.data));
            });
        });
    }

}
