<template>
<div>
    <div class="primary lighten-3 py-2 px-5">
        <span class="primary--text text--darken-1">Legend:</span>
    </div>
    <div class="pt-3 pb-6 px-5">
        <v-row>
            <v-col>
                <div class="d-flex align-center">
                    <div class="iconWrapper me-2">
                        <circle-icon class="fill-primary"></circle-icon>
                    </div>
                    <span>Public</span>
                </div>
            </v-col>
            <v-col>
                <div class="d-flex align-center">
                    <div class="iconWrapper me-2">
                        <circle-icon class="fill-red"></circle-icon>
                    </div>
                    <span>Under repair</span>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="d-flex align-center">
                    <div class="iconWrapper me-2">
                        <circle-icon class="fill-purple"></circle-icon>
                    </div>
                    <span>High power</span>
                </div>
            </v-col>
            <v-col>
                <div class="d-flex align-center">
                    <div class="iconWrapper me-2">
                        <circle-icon class="fill-yellow"></circle-icon>
                    </div>
                    <span>Residential</span>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="d-flex align-center">
                    <div class="iconWrapper me-2">
                        <circle-icon class="fill-light-blue"></circle-icon>
                    </div>
                    <span>Restricted</span>
                </div>
            </v-col>
            <v-col>
                <div class="d-flex align-center">
                    <div class="iconWrapper me-2">
                        <circle-icon class="fill-dark-purple"></circle-icon>
                    </div>
                    <span>Place</span>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="d-flex align-center">
                    <div class="iconWrapper me-2">
                        <circle-icon class="fill-blue"></circle-icon>
                    </div>
                    <span>In use</span>
                </div>
            </v-col>
            <v-col></v-col>
        </v-row>
    </div>
</div>
</template>

<script>
import CircleIcon from '../icons/circleIcon'
export default {
    name: "HelpLegend",
    components: {
        CircleIcon
    }
}
</script>

<style scoped lang="scss">
.iconWrapper{
    border: 1px solid #009B42;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width: 14px;
        height: auto;
    }
    .fill-primary{
        fill: #009B42;
    }
    .fill-red{
        fill: #F63737;
    }
    .fill-purple{
        fill: #CA4FEF;
    }
    .fill-yellow{
        fill: #F3B918;
    }
    .fill-light-blue{
        fill: #06E0D8;
    }
    .fill-dark-purple{
        fill: #5E46FF;
    }
    .fill-blue{
        fill: #12B5DD;
    }
}
</style>
