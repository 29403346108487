<template>
    <v-list-item-content class="pb-5">
        <v-row no-gutters>
            <v-col cols="6" lg="6" class="relative" v-for="plug in plugs" :key="plug.id">
                <input type="checkbox" :id="plug.name" name="outlets" :value="plug.id" v-model="pickedPlugs">
                <label :for="plug.name">
                    <img v-if="plug.id <= 8" v-bind:src='"../../../assets/images/plugs/"+plug.id+".svg"' alt=""/>
                    <img v-else src="../../../assets/images/plugs/default.svg" alt=""/>
                </label>
                <p>{{ plug.name }}</p>
            </v-col>
        </v-row>
    </v-list-item-content>
</template>

<script>
import Plug from "../../../models/Plug";
export default {
    name: "PlugType",
    data(){
        return{
            plugs: [],
            pickedPlugs: [],
        }
    },
    mounted() {
        this.loadPlugs();
    },
    methods: {
        async loadPlugs(){
            this.plugs = await Plug.all();
        }
    },
    watch: {
        pickedPlugs(){
            this.$store.commit('SET_UI_FILTERS_PLUGS', this.pickedPlugs);
        }
    },
    beforeDestroy() {
        this.$store.commit('SET_UI_FILTERS_PLUGS', []);
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/PlugType";
</style>
