<template>
    <section class="login">
        <div class="py-10">
            <user-icon></user-icon>
        </div>
        <login-form :errors="errors" @loginUser="loginUser"></login-form>
        <div class="fakeDiv"></div>
        <register-button></register-button>
    </section>
</template>

<script>
    import axios from 'axios';
    import {mapActions} from "vuex";
    import UserIcon from "../components/icons/UserIcon";
    import LoginForm from "../components/LoginForm";
    import RegisterButton from "../components/RegisterButton";
    import router from "../router";

    export default {
        data() {
            return {
                errors: {},
            }
        },
        methods: {
            ...mapActions('auth', ['login']),
            loginUser(credentials) {
                axios.get('/sanctum/csrf-cookie').then(() => {
                    axios.post('/login', {
                        email: credentials.email,
                        password: credentials.password,
                        remember_me: credentials.remember_me,
                    }).then(() => {
                        this.login();
                        router.push({name: 'Home'})
                    }).catch((error) => {
                        this.errors = error.response.data.errors;
                    });
                });

            },
        },
        components: {
            UserIcon,
            LoginForm,
            RegisterButton
        }
    }
</script>

<style lang="scss" scoped>
    .login {
        width: 100%;
        //height: calc(100vh - 40px);
        min-height: 100%;
        position: relative;

        .fakeDiv {
            height: 190px;
        }

        @media only screen and (min-width: 576px) {
            width: 80%;
            margin: auto;
        }
        @media only screen and (min-width: 776px) {
            width: 600px;
        }
    }
</style>
