import mapsLoader from "./gMapsLoader";

export default class MapRenderer {
    markers = [];

    constructor(mapElement, props) {
        this.mapElement = mapElement;
        this.mapOptions = {
            center: props.center,
            zoom: props.zoom,
            minZoom: props.minZoom,
            mapTypeControl: props.mapTypeControl,
            fullscreenControl: props.fullscreenControl,
            streetViewControl: props.streetViewControl,
            disableDefaultUI: props.disableDefaultUI,
        };
    }

    async render() {
        this.google = await mapsLoader();
        this.map = new this.google.maps.Map(this.mapElement, this.mapOptions);
    }

    onMapClick(callback) {
        this.map.addListener("click", () => {
            this.resetAllMarkerIcons();
            callback();
        });
    }

    addMarker(marker, clickCallback) {
        const m = {
            mapMarker: new this.google.maps.Marker({
                position: marker.position,
                icon: marker.attributes.passive,
                map: this.map,
            }),
            attributes: marker.attributes
        }


        m.mapMarker.addListener("click", () => {
            this.map.addListener("click", () => {
                m.mapMarker.setIcon(m.attributes.passive);
            }, {once: true});

            if (clickCallback) {
                clickCallback(marker, m.mapMarker);
            }
        });

        this.markers.push(m);
    }

    focusMarker(markerId) {
        this.resetAllMarkerIcons();
        const marker = this.markers.find(m => m.attributes.id === markerId);
        this.map.panTo(marker.mapMarker.getPosition());
        marker.mapMarker.setIcon(marker.attributes.active);
    }

    filterMarkers(filters, arr) {
        this.markers.forEach((m) => {
            const {filterable} = m.attributes;
            if (this.#passesFilters(filterable, filters, arr)) {
                m.mapMarker.setMap(this.map);
            } else {
                m.mapMarker.setMap(null);
            }
        });
    }

    setUserMarker(location, clickCallback) {
        const userMarker = new this.google.maps.Marker({
            position: location,
            title: 'my location',
            icon: '/img/markerUser.png',
            map: this.map,
        });
        userMarker.setPosition({
            lat: location.lat,
            lng: location.lng,
        });
        this.map.setCenter(location);
        this.map.setZoom(8);
        if (clickCallback) {
            clickCallback(userMarker);
        }
    }

    resetAllMarkerIcons() {
        this.markers.forEach((m) => {
            m.mapMarker.setIcon(m.attributes.passive);
        });
    }

    #passesFilters(properties, filters, arr) {
        for (const prop in filters) {
            if (Object.prototype.hasOwnProperty.call(filters, prop)) {
                let propertyCollection;
                if (prop !== arr) {
                    propertyCollection = filters[prop];
                } else {
                    propertyCollection = properties[prop].filter(item => !filters[prop].includes(item));
                }

                if (propertyCollection.length === 0) {
                    continue
                }

                if (!propertyCollection.includes(properties[prop]) && prop !== arr) {
                    return false;
                } else if (filters[prop].length > 0 && prop === arr) {
                    return false;
                }
            }
        }

        return true;
    }
}
