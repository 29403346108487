<template>
    <div class="buttonWrap">
        <v-container>
            <v-btn class="font-weight-regular text-none elevation-0" @click="backToMenu(url)">
                <v-icon>mdi-chevron-left</v-icon>
                {{ text }}
            </v-btn>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "BackToMenu",
    props: {
        text: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    },
    methods: {
        backToMenu(url){
            this.$router.push({
                name: url,
            })
        },
    }
}
</script>

<style scoped lang="scss">
.container{
    margin-left: 0;
    padding-bottom: 0;
    @media only screen and (min-width: 1200px){
        margin-left: 60px;
    }
}
.buttonWrap{
    background-color: #0E8957;
    @media only screen and (min-width: 768px){
        background-color: transparent;
        padding-top: 10px;
    }
}
.v-btn:not(.v-btn--round).v-size--default{
    padding: 0;
    font-size: 16px;
    color: white;
    background-color: transparent;
    border: none;
    @media only screen and (min-width: 768px){
        border: 1px solid #009B42;
        padding: 8px 16px;
        color: #009B42;
        border-radius: 30px;
    }

    i{
        padding-right: 10px;
        @media only screen and (min-width: 768px){
            display: none;
        }
    }
}
</style>
