import Vehicle from "../models/Vehicle";
import {mapGetters} from "vuex";
import Vue from 'vue';

export const VehicleMixin = {
    data() {
        return {
            idVehicle: parseInt(this.$route.params.id),
            name: '',
            mileage: null,
            availableVehicles: [],
            variants: [],
            selectedManufacturer: null,
            selectedModel: null,
            selectedYear: null,
            selectedVehicleVariantId: null,
            selectedVehicleVariant: null,
        }
    },
    async mounted() {
        this.availableVehicles = await Vehicle.all();
        if (this.idVehicle) {
            this.name = this.vehicle.name;
            this.mileage = this.vehicle.mileage;
            const userVehicle = this.vehicles.find(v => v.id === this.idVehicle);
            this.selectedManufacturer = userVehicle.getManufacturer();
            Vue.nextTick(() => {
                this.selectedModel = userVehicle.getModel();
                Vue.nextTick(() => {
                    this.selectedYear = userVehicle.getYear();
                })
            })
        }
    },
    computed: {
        ...mapGetters( 'auth', ['vehicles']),
        vehicle() {
            return this.vehicles.find(v => v.id === this.idVehicle);
        },
        manufacturers() {
            return [...new Set(this.availableVehicles.map(v => v.manufacturer))];
        },
        models() {
            return [...new Set(this.availableVehicles
                .filter(v => v.manufacturer === this.selectedManufacturer)
                .map(v => v.model))];
        },
        years() {
            return [...new Set(this.availableVehicles
                .filter(v => v.manufacturer === this.selectedManufacturer)
                .filter(v => v.model === this.selectedModel)
                .map(v => v.year))];
        },
    },
    methods: {
        filterVehicle() {
            const vehicle = this.availableVehicles
                .filter(v => v.manufacturer === this.selectedManufacturer)
                .filter(v => v.model === this.selectedModel)
                .find(v => v.year === this.selectedYear)

            this.loadVariants(vehicle);
        },
        async loadVariants(vehicle) {
            await vehicle.loadVariants();
            this.variants = vehicle.variants;

            if (this.idVehicle && this.variants.map(v => v.id).includes(this.vehicle.getVariant().id)) {
                this.selectedVehicleVariantId = this.vehicle.getVariant().id;
                this.selectedVehicleVariant = vehicle.variants.battery_capacity;
            }
        }
    },
    watch: {
        selectedYear() {
            this.selectedVehicleVariantId = null;
            if(this.selectedYear){
                this.selectedVehicleVariant = null;
                this.filterVehicle();
            }
        },
        selectedManufacturer() {
            this.selectedModel = null;
        },
        selectedModel() {
            this.selectedYear = null;
        },
        selectedVehicleVariantId(){
            if(this.selectedVehicleVariantId){
                this.selectedVehicleVariant = this.variants.find(v => v.id === this.selectedVehicleVariantId).battery_capacity;
            }
        },
    },
}
