<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-card>
            <v-card-title class="primary lighten-2 white--text py-3 d-flex justify-space-between">
                <span>Calculator</span>
                <a @click="closeCalculator" >
                    <v-icon class="white--text">mdi-close</v-icon>
                </a>
            </v-card-title>
            <v-card-text class="py-3">
                <div>
                    <v-select :items="vehicles" :value="vehicles" item-text="name" label="Choose Your Vehicle" v-model="chosenVehicle"></v-select>
                </div>
                <div>
                    <!-- TODO - vidjeti što će se ovdje uzimati za izračun kasnije -->
                    <v-select :items="stationData.outlet_types" :value="stationData.outlet_types" item-text="name" label="Choose station's plug" v-model="chosenPlug"></v-select>
                </div>
                <div>
                    <p class="grey--text text--darken-1 font-weight-black subheading"><span>Time to charge: </span>
                        <span v-if="chosenVehicle && chosenPlug">
                        2.5h
                    </span>
                        <span v-else>/</span>
                    </p>
                </div>
                <div>
                    <p class="grey--text text--darken-1 font-weight-black subheading"><span>Total charging price: </span>
                        <span v-if="chosenVehicle && chosenPlug">
                        5,54€
                        </span>
                        <span v-else>/</span>
                    </p>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import Plug from "../../models/Plug";

export default {
    name: "ChargingCalculator",
    props: {
        stationData: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            allVehicles: [],
            plugs: [],
            dialog: true,
            chosenVehicle: null,
            chosenPlug: null,
        }
    },
    computed: {
        ...mapGetters('auth', ['vehicles']),
    },
    mounted() {
        this.loadVehicles();
        this.loadPlugs();
    },
    methods: {
        loadVehicles() {
            this.allVehicles = this.vehicles;
        },
        async loadPlugs(){
            /*TODO - hoće li jačina/brzina punjenja ovisiti o plugu kao i stanici ili?
                    - ovisno o tome vidjeti što tu točno, zasad je select kao plugovi navedeni u punionici
                    - provjera koji plugovi odgovaraju kojem autu???
             */
            this.plugs = await Plug.all();
        },
        closeCalculator() {
            this.dialog = false;
            this.$emit("closeCalculator");
        },

    },
    watch: {
        dialog() {
            if (this.dialog === false) {
                this.closeCalculator();
            }
        },
    }
}
</script>

<style scoped lang="scss">
</style>
