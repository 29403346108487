<template>
    <div class="navigationFooter">
        <div class="buttonWrap">
            <!-- NELOGIRANI USER -->
            <v-btn color="primary" rounded large :to="{name: 'Login'}" class="mb-4" v-if="!check">
                Login
            </v-btn>
            <v-btn outlined color="primary" rounded large :to="{name: 'Register'}" v-if="!check">
                Register
            </v-btn>
            <!-- LOGIRANI USER -->
            <v-btn outlined color="primary" rounded large v-if="check" @click="logout">
                Logout
            </v-btn>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('auth', ['user', 'check']),
    },
    methods:{
        logout(){
            this.$store.dispatch('auth/logout');
            this.$router.push({name: 'Login'});
        }
    },
}
</script>
<style lang="scss" scoped>
.navigationFooter {
    position: absolute;
    bottom: 30px;
    width: 100%;
    left: 0;
    right: 0;

    .buttonWrap {
        position: relative;
        padding: 0 25px;

        a, button {
            width: 100%;
        }
    }
}
</style>
