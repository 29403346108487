import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
import LoginScreen from '../views/LoginScreen';
import RegisterScreen from '../views/RegisterScreen';
import ForgotPassword from '../views/ForgotPassword';
import ProfileScreen from '../views/ProfileScreen';
import UserVehicle from '../views/UserVehicles';
import EditVehicle from "../views/EditVehicle";
import FavouritesScreen from '../views/FavouritesScreen';
import ChargingHistory from '../views/ChargingHistory';
import ReservationScreen from '../views/ReservationScreen';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginScreen
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterScreen
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/profile',
        name: 'ProfileScreen',
        component: ProfileScreen,
        meta: { requiresAuth: true },
    },
    {
        path: '/user-vehicle',
        name: 'UserVehicle',
        component: UserVehicle,
        meta: { requiresAuth: true },
    },
    {
        path: '/edit-vehicle/:id',
        name: 'EditVehicle',
        component: EditVehicle,
        meta: { requiresAuth: true },
    },
    {
        path: '/favourites',
        name: 'FavouritesScreen',
        component: FavouritesScreen,
        meta: { requiresAuth: true }
    },
    {
        path: '/charging-history',
        name: 'ChargingHistory',
        component: ChargingHistory,
        meta: { requiresAuth: true }
    },
    {
        path: '/reservations',
        name: 'ReservationScreen',
        component: ReservationScreen,
        meta: { requiresAuth: true }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/check']) {
            next();
        } else {
            store.dispatch('auth/getUser').then(() => {
                if (store.getters['auth/check']) {
                    next();
                } else {
                    next({ name: 'Login' });
                }
            });
        }
    } else {
        next();
    }
})

export default router
