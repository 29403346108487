import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                primary: {
                    base: '#009B42',
                    darken1: '#0E8957',
                    lighten3: '#E7F4EF'
                },
            },
        },
    },
})


Vue.use(Vuetify, {
    theme: {
        themes: {
            light: {
                primary: '#009B42'
            }
        }
    }
})



export default vuetify
