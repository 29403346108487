<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" lg="4">
                <v-card flat>
                    <div class="primary lighten-3 py-2 px-5">
                        <v-icon class="primary--text">mdi-map-marker-outline</v-icon>
                        Centar Tower
                    </div>
                    <v-card-text class="text--primary">
                        <div class="py-2">
                            <v-row no-gutters class="d-flex align-center">
                                <v-col>
                                    <div class="d-flex align-start py-2">
                                        <v-icon class="pe-3">mdi-map-marker-radius-outline</v-icon>
                                        Ul. Janka Polića Kamova 81a,
                                        51000, Rijeka
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-phone-in-talk</v-icon>
                                        051 403 815
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-handshake-outline</v-icon>
                                        HEP
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-information-outline</v-icon>
                                        02.10.2021. - 13:28h <br>
                                        kWh: 3 / duration: 00:30:25
                                        Total price: $$
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-card flat>
                    <div class="primary lighten-3 py-2 px-5">
                        <v-icon class="primary--text">mdi-map-marker-outline</v-icon>
                        Centar Tower
                    </div>
                    <v-card-text class="text--primary">
                        <div class="py-2">
                            <v-row no-gutters class="d-flex align-center">
                                <v-col>
                                    <div class="d-flex align-start py-2">
                                        <v-icon class="pe-3">mdi-map-marker-radius-outline</v-icon>
                                        Ul. Janka Polića Kamova 81a,
                                        51000, Rijeka
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-phone-in-talk</v-icon>
                                        051 403 815
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-handshake-outline</v-icon>
                                        HEP
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-information-outline</v-icon>
                                        02.10.2021. - 13:28h <br>
                                        kWh: 3 / duration: 00:30:25
                                        Total price: $$
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-card flat>
                    <div class="primary lighten-3 py-2 px-5">
                        <v-icon class="primary--text">mdi-map-marker-outline</v-icon>
                        Centar Tower
                    </div>
                    <v-card-text class="text--primary">
                        <div class="py-2">
                            <v-row no-gutters class="d-flex align-center">
                                <v-col>
                                    <div class="d-flex align-start py-2">
                                        <v-icon class="pe-3">mdi-map-marker-radius-outline</v-icon>
                                        Ul. Janka Polića Kamova 81a,
                                        51000, Rijeka
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-phone-in-talk</v-icon>
                                        051 403 815
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-handshake-outline</v-icon>
                                        HEP
                                    </div>
                                    <div class="d-flex align-center py-2">
                                        <v-icon class="pe-3">mdi-information-outline</v-icon>
                                        02.10.2021. - 13:28h <br>
                                        kWh: 3 / duration: 00:30:25
                                        Total price: $$
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "HistoryCard"
}
</script>

<style scoped lang="scss">
@import "../assets/scss/Card";
</style>
