<template>
    <section class="register">
        <div class="py-10">
            <user-icon></user-icon>
        </div>
        <register-form :errors="errors" @registerUser="registerUser"></register-form>
        <div class="fakeDiv"></div>
        <login-button></login-button>
    </section>
</template>

<script>
    //import axios from 'axios';

    import UserIcon from "../components/icons/UserIcon";
    import RegisterForm from "../components/RegisterForm";
    import LoginButton from "../components/LoginButton";
    import axios from "axios";
    import router from "../router";

    export default {
        data() {
            return {
                errors: [],
            }
        },
        methods: {
            registerUser(credentials) {
                axios.get('/sanctum/csrf-cookie').then(() => {
                    axios.post('/register', {
                        email: credentials.email,
                        name: credentials.name,
                        password: credentials.password,
                        password_confirmation: credentials.password_confirmation,
                    }).then(() => {
                        router.push({name: 'Home'})
                    }).catch((error) => {
                        this.errors = error.response.data.errors;
                    });
                });
            },
        },
    components:{
        UserIcon,
        RegisterForm,
        LoginButton
    }
}
</script>

<style lang="scss" scoped>
.register {
    width: 100%;
    //height: calc(100vh - 40px);
    min-height: 100%;
    position: relative;

    .fakeDiv{
        height: 190px;
    }

    @media only screen and (min-width: 576px){
        width: 80%;
        margin: auto;
    }
    @media only screen and (min-width: 776px){
        width: 600px;
    }
}
</style>
