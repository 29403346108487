<template>
    <form @submit.prevent="sendUserData">
        <div class="form-group pb-3">
            <input type="email"
                   class="form-control"
                   name="email"
                   placeholder="Email"
                   autocomplete="email"
                   v-model="email"
                   required>
        </div>
        <div class="form-group pb-3">
            <input type="text"
                   class="form-control"
                   name="name"
                   placeholder="Name"
                   autocomplete="name"
                   v-model="name"
                   required>
        </div>
        <div class="form-group pb-3">
            <input type="password"
                   class="form-control"
                   placeholder="Password"
                   v-model="password"
                   autocomplete="new-password"
                   name="password"
                   required>
        </div>
        <div class="form-group pb-3">
            <input type="password"
                   class="form-control"
                   placeholder="Repeat password"
                   v-model="repeatPassword"
                   autocomplete="new-password"
                   name="repeat-password"
                   required>
        </div>
        <v-alert type="error" class="dense" v-if="errorPass.length > 0">
            {{ errorPass }}
        </v-alert>
        <div class="form-group pt-4 text-center">
            <v-btn class="primary" rounded large type="submit">Register</v-btn>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            email: "",
            password: "",
            repeatPassword: "",
            name: '',
            errorPass: '',
        }
    },
    methods: {
        sendUserData() {
            if(this.password.length >= 8){
                if(this.password === this.repeatPassword){
                    this.errorPass = '';
                    this.$emit("registerUser", {
                        email: this.email,
                        name: this.name,
                        password: this.password,
                        password_confirmation: this.repeatPassword,
                    });
                }
                else{
                    this.errorPass = 'The passwords are not the same';
                }
            }else{
                this.errorPass = 'Your password must be at least 8 characters';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
form{
    width: 85%;
    display: block;
    margin: auto;
    .form-group{
        position: relative;
        .eyeIcon {
            position: absolute;
            right: 16px;
            margin-top: 10px;
            text-decoration: none;
        }
        input {
            width: 100%;
            height: 42px;
            padding: 0 40px 0 20px;
            border-radius: 25px;
            background-color: #E7F4EF;
            &:active, &:hover, &:focus{
                background-color: #E7F4EF;
            }
        }
        input::placeholder{
            color: #009B42;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px #E7F4EF inset ;
        }
        button{
            width: 100%;
        }
    }
}
</style>
