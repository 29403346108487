<template>
    <form @submit.prevent="sendUserData" >
        <div class="form-group pb-3">
            <input type="email" class="form-control" name="email" placeholder="Email" v-model="email" required>
            <v-alert type="error" dense v-if="errors.email" class="mt-4">
                {{ errors.email[0] }}
            </v-alert>
        </div>
        <div class="form-group">
            <input :type="visibility" class="form-control" placeholder="Password" v-model="password"
                   name="current-password" autocomplete="on" required>
            <a href="#" class="eyeIcon" @click="showPassword">
                <v-icon>mdi-eye</v-icon>
            </a>
            <v-alert type="error" v-if="errors.password">
                {{ errors.password[0] }}
            </v-alert>
        </div>
        <div class="form-check d-flex justify-space-between align-center">
            <v-checkbox v-model="checkbox" class="grey--text text--darken-2" label="Remember me"></v-checkbox>
            <router-link :to="{name: 'ForgotPassword'}" class="grey--text text--darken-2">Forgot password?</router-link>
        </div>
        <div class="form-group pt-4 text-center">
            <v-btn class="primary" type="submit" rounded large>Login</v-btn>
        </div>
    </form>
</template>

<script>
export default {
    props: {
      errors: {
          type: Object,
      }
    },
    data() {
        return {
            email: "",
            password: "",
            checkbox: false,
            visibility: "password",
        }
    },
    methods: {
        showPassword() {
            if (this.visibility === 'text') {
                this.visibility = 'password'

            } else {
                this.visibility = 'text';
            }
        },
        sendUserData(){
            this.$emit("loginUser", {
                email: this.email,
                password: this.password,
                remember_me: this.checkbox,
            });
        }
    }
}
</script>

<style lang="scss" scoped>
form{
    width: 85%;
    display: block;
    margin: auto;

    .form-group{
        position: relative;
        .eyeIcon {
            position: absolute;
            right: 16px;
            margin-top: 10px;
            text-decoration: none;
        }
        input {
            width: 100%;
            height: 42px;
            padding: 0 40px 0 20px;
            border-radius: 25px;
            background-color: #E7F4EF;
            &:active, &:hover, &:focus{
                background-color: #E7F4EF;
            }
        }
        input::placeholder{
            color: #009B42;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px #E7F4EF inset ;
        }
        button{
            width: 100%;
        }
    }
}
</style>
