<template>
    <v-container class="vehicle">
        <p class="primary--text">Add new vehicle: </p>
        <form @submit.prevent="addVehicle">
            <v-row>
                <v-col cols="12" md="6">
                    <input type="text" class="form-control primary lighten-3" id="name" name="name"
                           placeholder="Name your vehicle..." v-model="name" aria-label="Name of Vehicle" required>
                </v-col>
                <v-col class="pb-10" cols="12" md="6">
                    <input type="text" class="form-control primary lighten-3" id="mileage" name="mileage"
                           placeholder="Mileage" v-model="mileage" aria-label="Name of Vehicle">
                </v-col>
                <v-col cols="12" md="6">
                    <v-select :items="manufacturers" background-color="primary lighten-3" label="Choose Manufacturer"
                              v-model="selectedManufacturer" rounded solo flat dense :rules="rule" required></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select :items="models" background-color="primary lighten-3" label="Choose Model"
                              v-model="selectedModel"
                              :disabled="selectedManufacturer === null" rounded solo flat dense :rules="rule" required></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select :items="years" background-color="primary lighten-3" label="Choose Year"
                              v-model="selectedYear"
                              :disabled="selectedModel === null" rounded solo flat
                              dense :rules="rule" required></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select :items="variants"
                              background-color="primary lighten-3"
                              label="Choose Battery Variant"
                              item-text="name"
                              item-value="id"
                              v-model="selectedVehicleVariantId"
                              :disabled="selectedYear === null"
                              rounded solo flat dense :rules="rule" required></v-select>
                </v-col>
                <v-col cols="12">
                    <p class="grey--text text--darken-1 px-2"><span>Battery Capacity: </span>
                        <span>{{ selectedVehicleVariant }}</span>
                    </p>
                </v-col>
            </v-row>
            <div class="d-flex justify-end">
                <v-btn outlined rounded large type="submit" :disabled="selectedVehicleVariantId === null || name.length < 1">
                    Save changes
                </v-btn>
            </div>
        </form>
    </v-container>
</template>

<script>
import {VehicleMixin} from "../../mixins/VehicleMixin";
import axios from "axios";
import {mapActions} from "vuex";

export default {
    name: "AddNewVehicle",
    data(){
        return{
            rule: [(v) => !!v || 'Item is required'],
        }
    },
    methods: {
        ...mapActions('auth', ['loadVehicles']),
        async addVehicle() {
                axios.post('/api/user/vehicles/', {
                    name: this.name,
                    mileage: this.mileage,
                    vehicle_variant_id: this.selectedVehicleVariantId,
                }).then(() => {
                    this.loadVehicles();
                    this.$emit('addVehicle');
                })
        },
    },
    mixins: [VehicleMixin],
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/vehicle";
</style>
