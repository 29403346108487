import Vue from 'vue'
import Vuex from 'vuex'

import auth from "./modules/auth";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLocationSelected: false,
        ui: {
            focusedChargingPoint: null,
            filters: {
                visiblePlugs: null,
                visiblePartners: null,
                visibleTerritories: null,
            }
        },
    },
    mutations: {
        SET_UI_FOCUSED_CP(state, cpId) {
            state.ui.focusedChargingPoint = cpId;
        },
        SET_UI_FILTERS_PLUGS(state, visiblePlugs) {
            if (visiblePlugs === []) {
                state.ui.filters.visiblePlugs = null;
                return;
            }

            state.ui.filters.visiblePlugs = visiblePlugs;
        },
        SET_UI_FILTERS_PARTNERS(state, visiblePartners) {
            if (visiblePartners === []) {
                state.ui.filters.visiblePartners = null;
                return;
            }

            state.ui.filters.visiblePartners = visiblePartners;
        },
        SET_UI_FILTERS_TERRITORIES(state, visibleTerritories) {
            if (visibleTerritories === []) {
                state.ui.filters.visibleTerritories = null;
                return;
            }

            state.ui.filters.visibleTerritories = visibleTerritories;
        },
        SET_UI_FILTERS_RESET(state) {
            //TODO: make universal
            state.ui.filters = {
                visiblePlugs: null,
                visiblePartners: null,
                visibleTerritories: null,
            }
        },
    },
    getters: {
        getFilters(state) {
            //removes null values
            // eslint-disable-next-line no-unused-vars
            return Object.fromEntries(Object.entries(state.ui.filters).filter(([_, v]) => v != null));
        },
        focusedChargingPoint(state) {
            return state.ui.focusedChargingPoint;
        },
    },
    actions: {
        setSelectedLocation({commit}, selectedLocation) {
            commit('SET_SELECTED_LOCATION', selectedLocation);
        },
        setIsLocationSelected({commit}, isLocationSelected) {
            commit('SET_IS_LOCATION_SELECTED', isLocationSelected);
        },
        setFocusedChargingPoint({commit}, cpId) {
            commit('SET_UI_FOCUSED_CP', cpId);
        },
    },
    modules: {
        auth,
    }
})
