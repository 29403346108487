<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" lg="4" v-for="vehicle in vehicles" :key="vehicle.id">
                <v-card flat>
                    <v-card-text class="text--primary">
                        <div class="py-2">
                            <span class="primary--text text--darken-1">{{ vehicle.name }}</span>
                            <div>
                                <span>{{ vehicle.getManufacturer() }} - {{
                                        vehicle.getModel()
                                    }}, Year {{ vehicle.getYear() }}.</span>
                                <span>Variant: {{ vehicle.getVariantName() }}</span>
                                <span>Battery capacity: {{ vehicle.getBatteryCapacity() | WattHours }}</span>
                                <span>Mileage: {{ vehicle.mileage }} km</span>
                                <span>Battery charger type: Type 1</span>
                            </div>
                        </div>
                    </v-card-text>
                    <v-divider v-if="actions"></v-divider>
                    <v-card-actions v-if="actions">
                        <div class="d-flex justify-space-between buttonWrap">
                            <router-link class="primary--text text--darken-1 d-flex align-center"
                                         :to="{ path: '/edit-vehicle/'+ vehicle.id}">
                                <span>Edit</span>
                                <v-icon class="primary--text text--darken-1 pl-1">mdi-square-edit-outline</v-icon>
                            </router-link>
                            <a class="red--text d-flex align-center" @click="openDelete(vehicle)"><span>Delete</span>
                                <v-icon class="red--text pl-1">mdi-trash-can-outline</v-icon></a>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <delete-vehicle v-if="openDialog" @cancel="cancel" @deleteVehicle="deleteVehicle"></delete-vehicle>
    </v-container>
</template>

<script>
import DeleteVehicle from "./DeleteVehicle";
import WattHours from "../../filters/WattHours";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
export default {
    name: "VehicleCard",
    props:{
        actions: {
            type: Boolean,
            required: true,
        }
    },
    data(){
        return{
            openDialog: false,
            vehicle: null,
        }
    },
    computed: {
        ...mapGetters('auth', ['vehicles']),
    },
    methods: {
        ...mapActions('auth', ['loadVehicles']),
        editThis(id) {
            this.$emit('edit', id)
        },
        openDelete(vehicle) {
            this.openDialog = true;
            this.vehicle = vehicle;
        },
        cancel(){
            this.openDialog = false;
            this.vehicle = null;
        },
        deleteVehicle(){
            axios.delete('/api/user/vehicles/' + this.vehicle.id, {
                name: this.vehicle.name,
                mileage: this.vehicle.mileage,
                vehicle_variant_id: this.vehicle.vehicleVariant.id,
            });
            this.loadVehicles();
            this.cancel();
        },
    },
    filters: {
        WattHours,
    },
    components: {
        DeleteVehicle
    }
}
</script>

<style scoped lang="scss">
.v-sheet.v-card{
    border: 1px solid lightgray;
    margin: 15px 0;
    border-radius: 20px;

    .v-divider {
        border-color: lightgrey;
    }

    .v-card__text, .v-card__actions{
        padding: 10px 20px;
    }
    .v-card__actions{
        a{
            text-decoration: none;
        }
    }
    span{
        display: block;
    }
    .buttonWrap{
        width: 100%;
    }
}
</style>
