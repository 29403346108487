import axios from "axios";
import UserVehicle from "./UserVehicle";

export default class User {
    constructor(data) {
        this.id = data.id;
        this.email = data.email;
        this.name = data.name;
        this.vehicles = [];
    }

    static async get() {
        const response = await axios.get('/api/user');

        return new User(response.data);
    }

    async getVehicles() {
        const {data} = await axios.get('/api/user/vehicles');

        this.vehicles = data.map(v => new UserVehicle(v));
    }

    async getFavoriteChargingPoints() {
        const {data} = await axios.get('/api/user/favorite');

        return data;
    }

    async addToFavourites(chargingPointId){
        await axios.post('/api/user/favorite', {
            charging_point_id: chargingPointId,
        });
    }

    async removeFromFavourites(chargingPointId){
        await axios.delete('api/user/favorite', { data: {
                charging_point_id: chargingPointId
            }
        })
    }
}
