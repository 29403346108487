<template>
    <v-dialog v-model="dialog" max-width="400" persistent>
        <v-card>
            <v-card-title class="primary lighten-2 white--text py-3 d-flex justify-space-between">
                <span>Reservation</span>
                <a @click="closeReservation">
                    <v-icon class="white--text">mdi-close</v-icon>
                </a>
            </v-card-title>
            <v-card-text class="py-3">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                        transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" label="Date picker" prepend-icon="mdi-calendar" readonly
                                      v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable first-day-of-week="1" color="primary" :min="today">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
                <div>
                    <p>Select start time</p>
                    <v-radio-group v-model="dayPart" row>
                        <v-radio label="AM" value="am"></v-radio>
                        <v-radio label="PM" value="pm"></v-radio>
                    </v-radio-group>
                    <v-row>
                        <v-col>
                            <v-select :items="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']" label="Select hour" dense v-model="hour"></v-select>
                        </v-col>
                        <v-col>
                            <v-select :items="['00', '15', '30', '45']" label="Select minutes" dense v-model="minute"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select :items="[1,2,3,4,5,6,7,8,9,10,11,12]" label="Select duration of reservation" dense v-model="duration"></v-select>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="grey" class="white--text elevation-0" @click="closeReservation">Cancel</v-btn>
                <v-btn color="green" class="white--text elevation-0"  @click="confirmReservation">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "ReservationForm",
    props: {
        stationData: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            dialog: true,
            date: null,
            today: null,
            dayPart: null,
            hour: null,
            minute: null,
            duration: null,
            menu: false,
            menuTime: false,
        }
    },
    mounted() {
        const current = new Date();
        this.today = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + (current.getDate() + 1);
        // this.timeToday = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    },
    methods: {
        closeReservation() {
            this.dialog = false;
            this.$emit("closeReservation");
        },
        confirmReservation(){}
    },
    watch: {
        dialog() {
            if (this.dialog === false) {
                this.closeReservation();
            }
        },
    }
}
</script>

<style scoped>

</style>
