export default class MapMarker {
    constructor(props) {
        this.position = {
            lat: props.marker.lat,
            lng: props.marker.lng
        }
        this.attributes = {
            id: props.marker.id,
            passive: props.url.passive,
            active: props.url.active,
            filterable: {
                plug: props.marker.outlet_types.map(type => type.id),
                partner: props.marker.partner.id,
                territory: props.marker.territory_id
            },
        }
    }
}
