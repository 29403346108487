import axios from "axios";
import User from "../../models/User";

export default {
    namespaced: true,
    state: {
        user: null,
        favoriteChargingPoints: [],
    },
    getters: {
        check(state) {
            return state.user !== null;
        },
        user(state) {
            return state.user;
        },
        vehicles(state) {
            return state.user.vehicles;
        },
        favourites(state){
            return state.favoriteChargingPoints;
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_FAVORITES(state, favorites) {
            state.favoriteChargingPoints = favorites;
        },
        async SET_VEHICLES(state, user){
            if(user.id === state.user.id){
                await user.getVehicles();
                state.user = user;
            }
        },
        async ADD_FAVORITE(state, chargingPointId) {
            state.favoriteChargingPoints.push(chargingPointId);
        },
        async REMOVE_FAVORITE(state, chargingPointId) {
            state.favoriteChargingPoints = state.favoriteChargingPoints.filter(cp => cp !== chargingPointId);
        }
    },
    actions: {
        async login({dispatch}) {
            dispatch('getUser');
        },
        async logout({commit}) {
            await axios.post('/logout')
            commit('SET_USER', null);
        },
        async getUser({commit, dispatch}) {
            try {
                const user = await User.get();
                commit('SET_USER', user);
                await user.getVehicles();
                dispatch('getFavorites');
            } catch (err) {
                commit('SET_USER', null);
            }
        },
        async loadVehicles({commit}){
            try {
                const user = await User.get();
                commit('SET_VEHICLES', user);
            } catch(err){
                commit('SET_USER', null);
            }
        },
        async getFavorites({commit, getters}) {
            const f = await getters.user.getFavoriteChargingPoints();
            commit('SET_FAVORITES', f);
        },
        async addFavourite({commit, getters}, chargingPointId){
            await getters.user.addToFavourites(chargingPointId);
            commit('ADD_FAVORITE', chargingPointId)
        },
        async removeFavourite({commit, getters}, chargingPointId){
            await getters.user.removeFromFavourites(chargingPointId);
            commit('REMOVE_FAVORITE', chargingPointId);
        },
    }

}
