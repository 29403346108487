<template>
    <div class="profile">
        <Header title="My profile"/>
        <back-button text="Back to menu" url="Home"></back-button>
        <profile-details></profile-details>
        <go-to-vehicles></go-to-vehicles>
        <v-container>
            <div class="btnWrap py-12">
                <v-btn outlined color="primary" rounded large @click="logout">
                    Logout
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>
    import BackButton from "../components/BackButton";
    import ProfileDetails from "../components/Profile/ProfileDetails";
    import GoToVehicles from "../components/Profile/GoToVehicles";
    import {mapGetters} from "vuex";
    import Header from "../components/Profile/Header";

    export default {
    methods:{
        logout(){
            this.$store.dispatch('auth/logout');
            this.$router.push({name: 'Login'});
        }
    },
    computed: {
        ...mapGetters('auth', ['user']),
    },
    components: {
        BackButton,
        ProfileDetails,
        GoToVehicles,
        Header,
    }
}
</script>

<style scoped lang="scss">
.container{
    margin-left: 0;
    @media only screen and (min-width: 1200px){
        margin-left: 60px;
    }
}
.profile{
    overflow-x: hidden;
    .btnWrap{
        @media only screen and (min-width: 992px){
            max-width: 850px;
        }
        button{
            width: 250px;
            display: block;
            margin: auto;
        }
    }
}
</style>
