import { render, staticRenderFns } from "./InfoContent.vue?vue&type=template&id=48c31508&scoped=true&"
import script from "./InfoContent.vue?vue&type=script&lang=js&"
export * from "./InfoContent.vue?vue&type=script&lang=js&"
import style0 from "./InfoContent.vue?vue&type=style&index=0&id=48c31508&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c31508",
  null
  
)

export default component.exports